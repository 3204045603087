<template>
  <page-content>
    <!-- <img src="@/assets/mock/header.png" style="    width: 100%;" /> -->
    <!-- <page-title
      heading="ยินดีต้อนรับเข้าสู่เว็บไซต์ E-LEARNING D.A.R.E. Thailand"
    ></page-title> -->

    <!-- <banner-image-home></banner-image-home> -->
    <div style="padding: 50px; background-color: #ededed;">
      <a href="/reason-registered">
        <p style="font-size: 1.5rem; color: rgb(135 131 131);">
          <b-icon icon="chevron-left" aria-hidden="true"></b-icon> ย้อนกลับ
        </p>
      </a>
      <br />
      <div
        style="padding: 3rem;background-color: #fff;"
        class="app-content--inner"
      >
        <div class="row">
          <div class="col-12">
            <h3 class="mt-4 text-center" style="font-size: 1.5rem">
              <b>รายชื่อผู้เรียน</b>
            </h3>
            <p class="text-center" style="font-size: 1.5rem">
              <b>English Language Company</b>
            </p>
          </div>
        </div>

        <br />
        <div class="row">
          <div class="col-8">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #fcc820;
                text-decoration-thickness: 3px;
              "
            >
              <b>ผู้สอน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-12">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  ดร.ปิยนุช ตั้งเจริญธรรมผล<br />
                  <span style="font-size: 0.8rem">
                    ตำแหน่ง:
                  </span>
                </p>
              </div>
              <div class="col-12" style="margin-top: -30px;">
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-2">
            <p
              style="
              font-size: 1.5rem;
                text-decoration: underline;
                color: #000;
                text-decoration-color: #fcc820;
                text-decoration-thickness: 3px;
              "
            >
              <b>ผู้เรียน</b>
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row">
              <div class="col-1">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  1.
                </p>
              </div>
              <div class="col-11">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  นาย สมพงศ์ พรประเสริฐกิจ<br />
                  <span style="font-size: 0.8rem">
                    โรงเรียน:
                  </span>
                </p>
              </div>
              <div class="col-12" style="margin-top: -30px;">
                <v-divider></v-divider>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-1">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  2.
                </p>
              </div>
              <div class="col-11">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  นาย สมพงศ์ พรประเสริฐกิจ<br />
                  <span style="font-size: 0.8rem">
                    โรงเรียน:
                  </span>
                </p>
              </div>
              <div class="col-12" style="margin-top: -30px;">
                <v-divider></v-divider>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-1">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  3.
                </p>
              </div>
              <div class="col-11">
                <p
                  style="
              margin-top: 10px;
              font-size: 1.3rem;
              "
                >
                  นาย สมพงศ์ พรประเสริฐกิจ<br />
                  <span style="font-size: 0.8rem">
                    โรงเรียน:
                  </span>
                </p>
              </div>
              <div class="col-12" style="margin-top: -30px;">
                <v-divider></v-divider>
              </div>
            </div>
          </div>
        </div>
        <br />

        <!-- <youtube
        :video-id="videoId"
        ref="youtube"
        @playing="playing"
        @paused="pause"
      ></youtube>
      <button @click="playVideo">play</button> -->
      </div>
    </div>
  </page-content>
</template>

<script>
import PageContent from "../../components/layout/PageContent";
// import VueYoutube from 'vue-youtube'
// import PageTitle from "../components/layout/PageTitle";
// import BannerImageHome from "../components/carousel/BannerImageHome";
// import News from "../components/carousel/News";
// import Reason from "../components/carousel/Reason";

export default {
  components: {
    PageContent,
    // VueYoutube
    // PageTitle,
    // BannerImageHome,
    // News,
    // Reason,
  },

  data() {
    return {
      videoId: "lG0Ys-2d4MA",
    };
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log("we are watching!!!");
    },
    pause() {
      console.log("pause!!!");
    },
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  &___width-override {
    max-width: 1440px !important;

    @media only screen and (max-width: 1660px) and (min-width: 1420px) {
      max-width: 1140px !important;
    }
    @media only screen and (max-width: 1419px) and (min-width: 1140px) {
      max-width: 960px !important;
    }

    @media only screen and (max-width: 1139px) and (min-width: 770px) {
      max-width: 760px !important;
    }

    @media (max-width: 760px) {
      max-width: 360px !important;
    }
  }
}
</style>
