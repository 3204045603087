<template>
  <b-modal
    v-model="$show"
    id="delete-modal"
    centered
    hide-header
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <h3 class="font-weight-bold">คุณต้องการเริ่มทำแบบทดสอบหรือไม่?</h3>

    <template #modal-footer="{  }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="dontDo"
      >
        ยกเลิก
      </b-button>
      <b-button size="sm" variant="primary" @click="deleteItem">
        <!-- <b-spinner v-if="isSubmiting" small label="submiting..."></b-spinner> -->
        <span>เริ่มทำเลย</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
// import { User } from "../../models";

export default {
  props: {
    show: Boolean,

    deleteData: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isSubmiting: false,
    };
  },

  model: {
    prop: "show",
    event: "update",
  },

  computed: {
    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },
  },

  methods: {
    async deleteItem() {
      // this.isSubmiting = true;
      // try {
      //   let promise = await User.api().destroy(this.deleteData.id);

      //   const { data } = promise.response;
      //   if (data && data.response_status == "SUCCESS") {
      //     this.$toast.success("ลบข้อมูลผู้ใช้งานสำเร็จ");
          this.$bvModal.hide("delete-modal");
          this.$emit("delete:success");
      //   } else {
      //     this.$toast.error("ลบข้อมูลผู้ใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      //   }
      // } catch (error) {
      //   console.log(error);
      //   this.$toast.error(error);
      // } finally {
      //   this.isSubmiting = false;
      // }
    },

    async dontDo() {
      // this.isSubmiting = true;
      // try {
      //   let promise = await User.api().destroy(this.deleteData.id);

      //   const { data } = promise.response;
      //   if (data && data.response_status == "SUCCESS") {
      //     this.$toast.success("ลบข้อมูลผู้ใช้งานสำเร็จ");
          this.$bvModal.hide("delete-modal");
          this.$emit("delete:not");
      //   } else {
      //     this.$toast.error("ลบข้อมูลผู้ใช้งานไม่สำเร็จ กรุณาลองใหม่อีกครั้ง");
      //   }
      // } catch (error) {
      //   console.log(error);
      //   this.$toast.error(error);
      // } finally {
      //   this.isSubmiting = false;
      // }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.$emit("update:deleteData", {});
    },
  },
};
</script>
