<template>
  <b-card>
    <b-row align-v="center" align-h="end" class="mb-4">
      <b-col cols="12">
        <filter-master
          v-model="formfilter"
          :m-headquarter-id="
            !authUser.canAccessAllHeadQuarters ? authUser.mHeadquarterId : null
          "
          :m-division-id="
            !authUser.canAccessAllDivisions ? authUser.mDivisionId : null
          "
          :m-station-id="
            !authUser.canAccessAllStations ? authUser.mStationId : null
          "
          :disabledInput="{
            headQuarter:
              !authUser.canAccessAllHeadQuarters &&
              authUser.mHeadquarterId !== null,
            division:
              !authUser.canAccessAllDivisions && authUser.mDivisionId !== null,
            station:
              !authUser.canAccessAllStations && authUser.mStationId !== null,
          }"
          col-headquarter="3"
          col-division="3"
          col-station="3"
          col-school="3"
          :hiddenInput="{ schoolClass: true }"
        >
        </filter-master>

        <!-- <filter-master
              v-model="formfilter"
              col-headquarter="3"
              col-division="3"
              col-station="3"
              col-school="3"
              :hidden-input="{ schoolClass: true }"
            >
            </filter-master> -->
      </b-col>
    </b-row>

    <b-row v-if="isFetching">
      <b-col cols="12" class="mb-3">
        <b-skeleton-img no-aspect height="350px"></b-skeleton-img>
      </b-col>

      <b-col cols="12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col v-if="!noData">
        <b-row>
          <b-col cols="12">
            <apexchart
              type="bar"
              height="350"
              :options="chartOptions"
              :series="series"
            />
          </b-col>

          <b-col cols="12">
            <apexchart
              type="bar"
              height="200"
              :options="chartOptions2"
              :series="series2"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col v-else>
        <b-row align-v="center" align-h="center" style="height: 520px">
          ไม่พบข้อมูล
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { map, debounce } from "lodash";
import { Auth, TeachingDare } from "../../models";
import FilterMaster from "../form/FilterMaster";

export default {
  components: {
    apexchart: VueApexCharts,
    FilterMaster,
  },

  data() {
    return {
      isFetching: false,

      noData: false,

      chartData: [],

      formfilter: {},

      chartOptions2: {
        chart: {
          offsetX: -10,
          offsetY: -10,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        plotOptions: {
          bar: {
            horizontal: true,
          },
        },

        colors: ["#d94c53", "#58b5f5"],

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: ["นักเรียนตามแผน", "นักเรียนจริง"],
          title: {
            text: "ทั้งหมด",
            rotate: 0,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          labels: {
            show: false,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          show: false,
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      },
    };
  },

  watch: {
    // $route: {
    //   immediate: true,
    //   handler: "onRouteOrFilterChanged",
    // },

    formfilter: {
      deep: true,
      handler: "onRouteOrFilterChanged",
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    chartOptions() {
      const { chartData = [] } = this;

      return {
        chart: {
          offsetX: -20,
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: false,
          },
        },

        colors: ["#d94c53", "#58b5f5"],

        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "smooth",
          colors: ["transparent"],
          width: 10,
        },

        grid: {
          borderColor: "#e7e7e7",
        },

        markers: {
          size: 6,
        },

        xaxis: {
          categories: chartData.map((record) => {
            const { fiscal_year, term } = record;

            let category = fiscal_year;

            if (term) {
              category += `/${term}`;
            }

            return category;
          }),
          title: {
            text: "ปีการศึกษา",
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        yaxis: {
          title: {
            text: "จำนวน",
            rotate: 0,
            offsetY: -130,
            offsetX: 30,
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
          labels: {
            style: {
              fontSize: "14px",
              fontFamily: "Noto Sans Thai, sans-serif",
            },
          },
        },

        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 20,
          offsetY: -5,
          fontSize: "16px",
          fontFamily: "Noto Sans Thai, sans-serif",
          markers: {
            width: 12,
            height: 12,
            strokeWidth: 0,
            strokeColor: "#fff",
            radius: 12,
            offsetX: 0,
            offsetY: 0,
          },
        },

        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Noto Sans Thai, sans-serif",
          },
        },
      };
    },

    series() {
      const { chartData = [] } = this;

      return [
        {
          name: "นักเรียนตามแผน",
          data: map(chartData, "total_plan_student").map((x) =>
            x ? parseInt(x) : 0
          ),
        },
        {
          name: "นักเรียนจริง",
          data: map(chartData, "total_actual_student").map((x) =>
            x ? parseInt(x) : 0
          ),
        },
      ];
    },

    series2() {
      const { series = [] } = this;

      return series.map((serie) => {
        return {
          ...serie,
          data: [
            serie.data.reduce((res, x) => {
              return res + x;
            }, 0),
          ],
        };
      });
    },
  },

  methods: {
    async onRouteOrFilterChanged() {
      await this.fetch();
    },

    async fetch() {
      let promise;
      let params = {};

      const { mHeadquarterId, mDivisionId, mStationId, mSchoolId } =
        this.formfilter;

      this.noData = false;
      this.isFetching = true;

      if (mHeadquarterId) {
        this.$set(params, "mHeadquarterId", mHeadquarterId);
      }

      if (mDivisionId) {
        this.$set(params, "mDivisionId", mDivisionId);
      }

      if (mStationId) {
        this.$set(params, "mStationId", mStationId);
      }

      if (mSchoolId) {
        this.$set(params, "mSchoolId", mSchoolId);
      }

      try {
        promise = await TeachingDare.api().getDashboard(params);

        const { data = [] } = promise.response.data;

        if (data.length) {
          this.chartData = [...data];
        } else {
          this.noData = true;
        }
      } catch (error) {
        this.$toast.error(
          "ไม่สามารถดึงสรุปข้อมูลจำนวนนักเรียนได้ กรุณาลองใหม่อีกครั้ง"
        );
      } finally {
        this.isFetching = false;
      }

      return promise;
    },
  },

  created() {
    this.fetch = debounce(this.fetch, 500);
  },
};
</script>
