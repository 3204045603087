<template>
  <div>
    <b-card
      overlay
      img-src="https://yuzudigital.com/reason_mock.png"
      text-variant="white"
      :footer-bg-variant="bgFooter"
      footer-text-variant="white"
    >
      <div class="text-right">
        <b-button href="/reason-registered" variant="primary" style="padding: 2px;">
          <p class="m-0" style="font-size: 0.8rem;">ดูรายละเอียด</p>
        </b-button>
      </div>
      <template #footer>
        <p>
          <b> English Language Company </b>
        </p>
        <b-progress height="0.3rem" max="100" style="background-color: hsl(45, 97%, 75%)">
          <b-progress-bar
            value="65"
            style="background-color: white"
          ></b-progress-bar>
        </b-progress>
        <p class="pt-3" style="color: hsl(45, 97%, 90%); font-size: 0.8rem">เรียนไปแล้ว 65%</p>
      </template>
    </b-card>
  </div>
</template>

<script>
export default {
  props: {
    bgFooter: {
      type: String,
      default: "primary",
    },
    cButton: {
      type: String,
      default: "primary",
    },
  },
};
</script>

