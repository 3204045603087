<template>
  <div>
    <page-content>
      <page-title heading="สรุปภาพรวมการสอน">
        <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh"
            />
          </b-button>
        </template>
      </page-title>

      <chart-teaching-dare ref="chart" class="mb-5" />
    </page-content>
    <br />
    <page-content>
      <page-title heading="Dashboard สรุปผลการประเมิน">
        <template #right>
          <b-button size="sm">
            <font-awesome-icon
              :spin="isRefreshing"
              size="sm"
              icon="sync"
              @click="refresh_survey"
            />
          </b-button>
        </template>
      </page-title>

      <chart-survey ref="chart_survey" class="mb-5" />
    </page-content>
  </div>
</template>

<script>
import PageContent from "../components/layout/PageContent";
import PageTitle from "../components/layout/PageTitle";
import ChartTeachingDare from "../components/chart/TeachingDare";
import ChartSurvey from "../components/chart/Survey";

export default {
  components: {
    PageContent,
    PageTitle,
    ChartTeachingDare,
    ChartSurvey,
  },

  data() {
    return {
      isRefreshing: false,
    };
  },

  methods: {
    refresh() {
      this.$nextTick(async () => {
        this.isRefreshing = true;

        await this.$refs.chart.fetch();

        setTimeout(() => {
          this.isRefreshing = false;
        }, 2000);
      });
    },

    refresh_survey() {
      this.$nextTick(async () => {
        this.isRefreshing = true;

        await this.$refs.chart_survey.fetch();

        setTimeout(() => {
          this.isRefreshing = false;
        }, 2000);
      });
    },
  },
};
</script>
